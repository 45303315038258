import "@hotwired/turbo-rails";
import "./controllers";
import "@shoelace-style/shoelace";
import { registerIconLibrary } from "@shoelace-style/shoelace/dist/utilities/icon-library.js";

registerIconLibrary('boxicons', {
    resolver: name => {
        let folder = 'regular';
        if (name.substring(0, 4) === 'bxs-') folder = 'solid';
        if (name.substring(0, 4) === 'bxl-') folder = 'logos';
        return `https://cdn.jsdelivr.net/npm/boxicons@2.0.5/svg/${folder}/${name}.svg`;
    },
    mutator: svg => svg.setAttribute('fill', 'currentColor')
});

registerIconLibrary('fa', {
    resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.substring(0, 4) === 'fas-') folder = 'solid';
        if (name.substring(0, 4) === 'fab-') folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.1/svgs/${folder}/${filename}.svg`;
    },
    mutator: svg => svg.setAttribute('fill', 'currentColor')
});

import "trix"
import "@rails/actiontext"
